import React, {useEffect, useState} from 'react';
import styles from './causes-info.module.scss';
import './causes-info.scss';
import {ContentInfo} from "../../components/content-info/content-info";
import {useParams, Link} from "react-router-dom";
import {Container, ProgressBar} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import Donation from "../../components/donation/donation";
import {CauseCard} from "../../components/cause-card/cause-card";
import APIService from "../../services/api-service/api-service";
import {connect} from "react-redux";
import {ActionButton} from "../../components/action-button/action-button";
import ReactMarkdown from "react-markdown";
import {faCalendarDays} from "@fortawesome/free-regular-svg-icons";
import ReactPlayer from 'react-player'
import HBox from "../../components/ui/containers/layouts/hbox";

const apiService = new APIService()

const CausesInfo = ({causes, clientUrlSecret, tags, categories, wording }) => {
    const params = useParams();

    const getCurrentEventsData = () => (
        causes.filter((cause) => cause.id === params.id)[0]
    );

    const [currentImage, setCurrentImage] = useState(getCurrentEventsData()?.image || '')

    const getTagLabel = (tag) => {
        return tags.find((item) => item.id === tag)?.name
    }

    const getCategoriesMenu = (projectCategories) => {
        const filteredCategories = categories.filter((category) => projectCategories.includes(category.id));
        return <div style={{marginBottom: 20}}>
            {filteredCategories.map((category, index) => {
                return <Link key={index} to={`/categories/${category.id}`}><ActionButton icon={false} mini={true} key={index}>{category.name}</ActionButton></Link>
            })}
        </div>
    }

    const validatePaymentClientSecret = async () => {
        try {
            const result = await apiService.validatePaymentIntent(params.id, clientUrlSecret);
            if (result) {
                if (window.fbq) window.fbq('track', 'Purchase', { projectId: params.id, transport: 'card' });
                window.alert('Payment successful')
                window.open(`/#/causes/${params.id}`, '_self')
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (params.id && clientUrlSecret) validatePaymentClientSecret()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientUrlSecret, params.id]);

    useEffect(() => {
        if (params.id && getCurrentEventsData() && !clientUrlSecret) {
            let project = getCurrentEventsData();
            if (window.fbq) window.fbq('track', 'ViewContent', { projectId: params.id, heading: project.title });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const currentProject = getCurrentEventsData();
    const {title, image, images, youtube, percentage, raised, goal, text, reward, budget, model, remaining, status} = currentProject;

    const otherProjects = causes.filter((cause) => cause.id !== params.id);
    const nearestProjects = otherProjects.sort((a, b) => new Date(a.deadline) - new Date(b.deadline));

    return (
        <div className={`${styles.causes} causes-info`}>

            <ContentInfo asideProjects={nearestProjects || []}>
                {image && <img src={currentImage || image} className={styles.causesInfoImage} alt="cause"/>}
                {images && <HBox style={{marginTop: 20}}>
                    {images.map((image, index) => <img key={index} src={image} style={{width: 50, height: 50, objectFit: 'cover', cursor: 'pointer', border: image === currentImage ? '#23a884 3px solid' : '#FFF 3px solid'}} alt="cause" onClick={() => setCurrentImage(image)}/>)}
                </HBox>}
                {youtube && <div style={{marginTop: 20}}><ReactPlayer width={'100%'} url={youtube} /></div>}
                <div className={styles.causeDetails}>
                    <h2 className={styles.title}>
                        {title}
                    </h2>
                    {status === 'approved' && <div style={{marginBottom: 20}}><FontAwesomeIcon
                        icon={faCalendarDays}/>&nbsp;&nbsp;{remaining} {wording['days-left']}{wording['left-heading']}
                    </div>}
                    {<div style={{marginBottom: 30}}><strong>{wording[`model-${model}`]}</strong>
                        <p>{wording[`model-${model}-note`]}</p></div>}
                    <div className={styles.progress}>
                        <div className={`${styles.progressBar}`}></div>
                    </div>
                    <ProgressBar now={percentage}/>
                    <h4>{`${wording['collected-heading']}:`}<span
                        className={styles.raised}>€{raised}</span> / <span>€{goal}</span></h4>
                </div>
                <div>
                    <div style={{marginBottom: 10}}><strong>{wording['reward-heading']}</strong></div>
                    <ReactMarkdown className={styles.reward}>
                        {reward}
                    </ReactMarkdown>
                </div>
                <ReactMarkdown className={styles.text}>
                    {text}
                </ReactMarkdown>
                {budget && <div>
                    <div style={{marginBottom: 10}}><strong>{wording['budget-heading']}</strong></div>
                    <ReactMarkdown className={styles.budget}>
                        {budget}
                    </ReactMarkdown>
                </div>}
                <div>
                    <div style={{marginBottom: 10}}><span>{wording['categories-heading']}</span></div>
                    {getCategoriesMenu(currentProject.categories)}
                </div>
                <div>
                    <div style={{marginBottom: 10}}><span>{wording['tags-heading']}</span></div>
                    {currentProject?.tags &&
                        <ul className={styles.benefits}>{currentProject.tags.map((tag, index) => <li key={index}>
                            <FontAwesomeIcon icon={faCheck}/>
                            {getTagLabel(tag)}
                        </li>)}</ul>}
                </div>
                {currentProject.status === 'approved' && <Donation className={styles.donation} itemId={params.id} rewards={reward}/>}
            </ContentInfo>
            <Container>
                <div className={styles.causeDetails}>
                    <h2 className={styles.title}>
                        {wording['more-projects-heading']}
                    </h2>
                    {!!otherProjects.length &&
                        <div className={styles.relatedCauses}>
                            {(otherProjects.filter(el => el.status === 'completed')).map((cause, causeIndex) => (
                                <div key={causeIndex} className={styles.relatedCausesCard}>
                                    <CauseCard wording={wording} mini cause={cause}/>
                                </div>
                            ))}
                        </div>
                    }
                </div>
            </Container>

        </div>
    )
}

const mapStateToProps = ({  system: { wording, tags, categories } }) => {
    return { wording, tags, categories };
};

export default connect(mapStateToProps)(CausesInfo);
