import store from "../../redux/store";
import { serverUrl } from "../../configs/keys";


const SERVER_URL = serverUrl

const URL_CROWD = '/crowdfunding';
const URL_AUTH = '/auth';

const getToken = () => {
    return store.getState().user.token
}

const getUserId = () => {
    return store.getState().user.userId
}

const getLanguage = () => {
    return store.getState().system.language
}

export default class APIService {

    getAsyncFetch = (url, method, obj, token, serverPrefix) => {

        const request = {
            method: method || 'GET',
            headers: (obj && obj.noJSON) ? {} : {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }
        if (token) {
            if (!getToken()) return;
            request.headers['Authorization'] = `Bearer ${getToken()}`;
        }
        if (obj) {
            request.body = obj.noJSON ? obj.item : JSON.stringify(obj);
        }

        return fetch((serverPrefix !== undefined ? serverPrefix : SERVER_URL) + url, request)
            .then(res => {
                return ([200, 201].includes(res.status)) ? res.json() : Promise.reject(res.status)
            })
            .then(res => {
                if (res === undefined) {
                    return Promise.reject(null);
                }
                return (res.result !== undefined ? Promise.resolve(res.result) : Promise.resolve(res?.message || res));
            })
            .catch(error => {
                return Promise.reject(error);
            })
    }

    // SYSTEM

    getUserIPLocation = async () => {
        return this.getAsyncFetch(`https://ipinfo.io?token=b2330d6ddc4521`, 'GET', null, false, '');
    };

    getCrowdfundingProjects = async (language=(getLanguage() || 'by')) => {
        return this.getAsyncFetch(`${URL_CROWD}/${language}`, 'GET');
    };

    getCrowdfundingProfile = async (language='by') => {
        return this.getAsyncFetch(`${URL_CROWD}/profile/${language}`, 'GET', null, true);
    };

    updateCrowdfundingProjects = async () => {
        return this.getAsyncFetch(`${URL_CROWD}/refresh`, 'GET');
    };

    getPaymentIntent = async (id, amount=50, skipReward=false, rewardsArray=null, teamDonation=false) => {
        const userId = getUserId()
        return this.getAsyncFetch(`${URL_CROWD}/pay/${id}`, 'POST', { amount, skipReward, rewardsArray, teamDonation }, !!userId);
    };

    getKunaPaymentIntent = async (id, amount=50, skipReward=false, rewardsArray=null, teamDonation=false) => {
        return this.getAsyncFetch(`${URL_CROWD}/kuna/${id}`, 'POST', { amount, skipReward, rewardsArray, teamDonation }, true);
    };

    getWalletIntent = async (id, amount=50, skipReward=false, rewardsArray=null, teamDonation=false) => {
        return this.getAsyncFetch(`${URL_CROWD}/wallet/${id}`, 'POST', { amount, skipReward, rewardsArray, teamDonation }, true);
    };

    validatePaymentIntent = async (id, clientSecretId) => {
        return this.getAsyncFetch(`${URL_CROWD}/validate/${id}`, 'POST', { clientSecretId }, true);
    };

    registerUserVerification = async () => {
        return this.getAsyncFetch(`${URL_CROWD}/verify`, 'POST', null, true);
    };

    validateUserVerification = async () => {
        return this.getAsyncFetch(`${URL_CROWD}/verify/user`, 'GET', null, true);
    };

    // AUTH

    loginUserByEmail = async (email, secondary) => {
        return this.getAsyncFetch(`${URL_AUTH}/email/login?partner=crowdfunding${secondary ? '&secondary=on' : ''}`, 'POST', { email });
    };

    validateUserPin = async (email, pin) => {
        return this.getAsyncFetch(`${URL_AUTH}/email/validate`, 'POST', { email, pin });
    };

    // SYSTEM

    loadSystemData = async (language='by') => {
        return this.getAsyncFetch(`${URL_CROWD}/system/${language}`, 'GET');
    };

}

