import React, {useEffect, useRef, useState} from 'react';
import styles from './donation.module.scss';
import {ActionButton} from "../action-button/action-button";
import StripeWidget from "../stripe/stripe-widget";
import LoginScreenEmail from "../login-screen-email";
import {connect} from "react-redux";
import VBox from "../ui/containers/layouts/vbox";
import {logoutUser, updateUserProfile} from "../../redux/actions/actions-user";
import { loadProjects } from "../../redux/actions/actions-projects";
import APIService from "../../services/api-service/api-service";
import {isUrl} from "../../helper/validators";
import ReactMarkdown from "react-markdown";
import {Switcher} from "../switcher/switcher";
import HBox from "../ui/containers/layouts/hbox";

const apiService = new APIService()

const Donation = ({ className, itemId, token, wording, rewards, userEmail, logoutUser, walletAmount, updateUserProfile, loadProjects }) => {

    const amounts = ['5', '10', '25'];
    const rewardPages = [wording['rewards-menu-1'],wording['rewards-menu-2']]

    const [amountInputValue, setAmountInputValue] = useState('');
    const [amountValue, setAmountValue] = useState(amounts[2]);
    const [skipReward, setSkipReward] = useState(false);
    const [teamDonation, setTeamDonation] = useState(true);
    const [rewardsArray, setRewardsArray] = useState([]);
    const [loading, setLoading] = useState(false);
    const [stripeWidget, setStripeWidget] = useState(false);
    const [paymentUrl, setPaymentUrl] = useState(null);
    const [rewardPage, setRewardPage] = useState(rewardPages[0]);

    /*
    useEffect(() => {
        if (skipReward) {
            setRewardsArray([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skipReward]);
     */

    const paymentDivRef = useRef(null);

    useEffect(() => {
        if (stripeWidget && paymentDivRef.current) {
            paymentDivRef.current?.scrollIntoView({ behavior: "smooth" })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripeWidget]);


    useEffect(() => {
        if (rewardPage === rewardPages[0]) {
            setRewardsArray([])
            setSkipReward(false)
        } else {
            setSkipReward(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rewardPage]);

    const processRewards = (rewards) => {
        if (!rewards) return;
        const rewardsSplitArray = rewards.split('\n')?.filter(el => el.trim()).map(el => el.trim());

        const rewardNumbersArray = rewardsSplitArray.map(el => {

           let elString = el.split(' — ').join(' - ')
           elString = elString.split(' : ').join(' - ')

           const [sumString, description] = elString.split(' - ')
           return {
                reward: description,
                sum: Number(sumString.match(/\d+/g)?.[0])
           }
        })



        return !skipReward ? <VBox gap={10} style={{marginTop: 20, textAlign: 'left'}}>
            <span>{wording['rewards-node']}</span>
            <VBox gap={10} style={{marginTop: 10}}>
                {rewardNumbersArray.map((rewardItem, index) => {
                    let isMore = rewardItem.sum > amountValue
                    return (
                        isMore ? null : <label key={index} className={'d-flex'} style={{gap: 6, alignItems: 'flex-start'}}>
                            <input type="checkbox" key={index} style={{marginTop: 6}} checked={rewardsArray.includes(`€${rewardItem.sum} - ${rewardItem.reward}`)} onClick={() => rewardsArray.includes(`€${rewardItem.sum} - ${rewardItem.reward}`) ? setRewardsArray(rewardsArray.filter(el => el !== `€${rewardItem.sum} - ${rewardItem.reward}`)) : setRewardsArray([...rewardsArray, `€${rewardItem.sum} - ${rewardItem.reward}`])} />
                            <ReactMarkdown>{`€${rewardItem.sum} - ${rewardItem.reward}`}</ReactMarkdown>
                        </label>
                    )})}
            </VBox>
        </VBox> : null
    }

    const onOptionChange = e => {
        setAmountInputValue('');
        setAmountValue(e.target.value);
    }

    const handleInputValue = e => {
        setAmountInputValue(e.target.value)
        setAmountValue(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (window.fbq) window.fbq('track', 'InitiateCheckout', { projectId: itemId, transport: 'card', amount: amountValue });
        setStripeWidget(true)
    }

    const handleCrypto = async () => {
       try {
           setLoading(true)
           const paymentLink = await apiService.getKunaPaymentIntent(itemId, amountValue, skipReward, rewardsArray, teamDonation)
           if (paymentLink && isUrl(paymentLink)) {
               setPaymentUrl(paymentLink)
               window.open(paymentLink, '_blank')
           }
           setLoading(false)
       } catch (e) {
           setLoading(false)
           console.log(e)
       }
    }

    const handleWallet = async () => {
        if (!walletAmount || isNaN(walletAmount) || !amountValue || isNaN(amountValue) || (walletAmount < amountValue)) {
            return window.alert(wording['wallet-limit-note'])
        }
        try {
            setLoading(true)
            if (window.fbq) window.fbq('track', 'InitiateCheckout', { projectId: itemId, transport: 'wallet', amount: amountValue });
            const result = await apiService.getWalletIntent(itemId, amountValue*100, skipReward, rewardsArray, teamDonation)
            if (result) {
                loadProjects()
                updateUserProfile()
                window.alert(wording['wallet-payment-success'])
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
            console.log(e)
        }
    }

    const isAmount = (amount) => amountValue === amount && !amountInputValue;

    const isPaymentRestricted = !!process.env.REACT_APP_CLOUD

    return (
        <div className={`${styles.donationSection} ${className}`}>
            <div style={{
                borderBottom: '1px #DDD solid',
                width: '100%',
                marginTop: 10,
                marginBottom: 10
            }}/>


            {isPaymentRestricted &&
                <h4 style={{alignText: 'left'}}>{wording['safety-notice']} <a style={{color: '#156751'}}
                                                                              href={'https://gronka.org'}>https://gronka.org</a>
                </h4>}

            {!stripeWidget && !isPaymentRestricted &&
                <HBox gap={10} align={'center'} style={{textAlign: 'left', marginTop: 20}}>
                    <mark style={{padding: '1px 10px', borderRadius: 10, background: '#23A884', color: '#FFF'}}>
                        {wording['payment-step-1']}
                    </mark>
                    <strong>{wording['payment-step-1-heading']}</strong></HBox>}
            {!stripeWidget && !isPaymentRestricted && <div className={styles.form}>
                <div className={styles.donateList}>
                    <div className={`${styles.box} ${isAmount(amounts[0]) ? styles.active : ''}`}>
                        <input
                            type="radio"
                            id="c1"
                            name="amount"
                            value={amounts[0]}
                            checked={isAmount(amounts[0])}
                            onChange={onOptionChange}
                        />
                        <label htmlFor="c1">
                            <span className={styles.checkIcon}></span>
                            <span className={styles.amount}>€{amounts[0]}</span>
                        </label>
                    </div>
                    <div className={`${styles.box} ${isAmount(amounts[1]) ? styles.active : ''}`}>
                        <input
                            type="radio"
                            id="c2"
                            name="amount"
                            value={amounts[1]}
                            checked={isAmount(amounts[1])}
                            onChange={onOptionChange}
                        />
                        <label htmlFor="c2">
                            <span className={styles.checkIcon}></span>
                            <span className={styles.amount}>€{amounts[1]}</span>
                        </label>
                    </div>
                    <div className={`${styles.box} ${isAmount(amounts[2]) ? styles.active : ''}`}>
                        <input
                            type="radio"
                            id="c3"
                            name="amount"
                            value={amounts[2]}
                            checked={isAmount(amounts[2])}
                            onChange={onOptionChange}
                        />
                        <label htmlFor="c3">
                            <span className={styles.checkIcon}></span>
                            <span className={styles.amount}>€{amounts[2]}</span>
                        </label>
                    </div>
                    <div className={`${styles.box} ${styles.enterAmount}`}>
                        <input
                            type="number"
                            placeholder={`-- ${wording['donation-option']} --`}
                            value={amountInputValue}
                            onChange={handleInputValue}
                        />
                    </div>
                </div>

                <div style={{
                    borderBottom: '1px #DDD solid',
                    width: '100%',
                    marginTop: 20,
                    marginBottom: 20
                }}/>

                <div>

                    <HBox gap={10} align={'center'} style={{textAlign: 'left', marginTop: 20}}>
                        <mark style={{padding: '1px 10px', borderRadius: 10, background: '#23A884', color: '#FFF'}}>
                            {wording['payment-step-2']}
                        </mark>
                        <strong>{wording['payment-step-2-heading']}</strong></HBox>

                    {!token && !isPaymentRestricted && <VBox style={{textAlign: 'left', marginTop: 30}}>
                        <strong>{`${wording['donation-email-notice']}`}</strong>
                        <LoginScreenEmail/>
                    </VBox>}

                    {token && <VBox gap={10} style={{textAlign: 'left', marginTop: 30}}>
                        <strong>{`${wording['donation-email-notice']}`}</strong>
                        <HBox gap={10}>
                            {userEmail}
                            <ActionButton mini onClick={logoutUser}>Змяніць</ActionButton>
                        </HBox>
                    </VBox>}
                </div>

                <div style={{
                    borderBottom: '1px #DDD solid',
                    width: '100%',
                    marginTop: 20,
                    marginBottom: 20
                }}/>

                {rewards && <HBox gap={10} align={'center'} style={{textAlign: 'left', marginTop: 20, marginBottom: 30}}>
                    <mark style={{padding: '1px 10px', borderRadius: 10, background: '#23A884', color: '#FFF'}}>
                        {wording['payment-step-3']}
                    </mark>
                    <strong>{wording['payment-step-3-heading']}</strong></HBox>}
                {rewards && token && <Switcher page={rewardPage} setPage={setRewardPage}
                                               pages={[wording['rewards-menu-1'], wording['rewards-menu-2']]}/>}
                {rewards && token && rewardPage === rewardPages[0] && processRewards(rewards)}

                {token && rewardPage === rewardPages[1] && !isPaymentRestricted &&
                    <VBox style={{textAlign: 'left', marginTop: 20}}>
                        <label style={{display: "flex", gap: 10}}>
                            <span style={{marginTop: -3}}>{wording['skip-reward-notice']}</span>
                        </label>
                    </VBox>}

                <div style={{
                    borderBottom: '1px #DDD solid',
                    width: '100%',
                    marginTop: 20,
                    marginBottom: 20
                }}/>

                {!paymentUrl &&
                    <HBox gap={10} align={'center'} style={{textAlign: 'left', marginTop: 20, marginBottom: 30}}>
                        <mark style={{padding: '1px 10px', borderRadius: 10, background: '#23A884', color: '#FFF'}}>
                            {wording['payment-step-4']}
                        </mark>
                        <strong>{wording['payment-step-4-heading']}</strong></HBox>}
                {!paymentUrl && token && <div style={{marginTop: 20}}>
                    <VBox width={'100%'} gap={10}>
                        <label className={'d-flex'} style={{gap: 10, alignItems: 'flex-start'}}>
                            <input type="checkbox" style={{marginTop: 6, width: 20, height: 20}}
                                   checked={teamDonation === true}
                                   onClick={() => setTeamDonation(!teamDonation)}/>
                            <p className={'pt-1'}>{`Дадаць 10% на падтрымку платформы Гронка 🤗`}</p>
                        </label>
                        {<ActionButton
                            className={styles.amountAction}
                            icon={false}
                            onClick={handleSubmit}
                            disabled={!token || loading}
                        >
                            {wording['pay-regular']}
                        </ActionButton>}
                        {/*<p><mark>⚠️ Данаты карткай не будуць працаваць 10.08 (тэхнічныя работы)</mark></p>*/}
                        {!isNaN(walletAmount) && <ActionButton secondary border
                                                               className={styles.amountAction}
                                                               icon={false}
                                                               onClick={() => window.confirm(wording['wallet-confirmation']) && handleWallet()}
                                                               disabled={!token || loading || (walletAmount < amountValue)}
                        >
                            {`${wording['wallet-button']} (€${walletAmount})`}
                        </ActionButton>}
                        { false && <ActionButton secondary border
                                      className={styles.amountAction}
                                      icon={false}
                                      onClick={handleCrypto}
                                      disabled={!token || loading}
                        >
                            {wording['pay-crypto']}
                        </ActionButton>}
                    </VBox>
                </div>}
                {paymentUrl && <div style={{marginTop: 20}}>{`${wording['pay-crypto-link']}`} <a href={paymentUrl}
                                                                                                 target={'_blank'}
                                                                                                 rel={'noreferrer noopener'}>{paymentUrl}</a>
                </div>}
            </div>}
            {stripeWidget && amountValue && <div ref={paymentDivRef} className={'pt-5'}>
                <StripeWidget skipReward={skipReward} teamDonation={teamDonation} rewardsArray={rewardsArray}
                              amount={Number(amountValue) * 100}
                              itemId={itemId} title={''}/>
            </div>}
        </div>
    )
}

const mapStateToProps = ({user: {token, userEmail, walletAmount}, system: {wording}}) => {
    return {token, userEmail, wording, walletAmount};
};

const mapDispatchToProps = (dispatch) => {
    return {
        logoutUser: logoutUser(dispatch),
        loadProjects: loadProjects(dispatch),
        updateUserProfile: updateUserProfile(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Donation);
